export default {
  pluginSelecter: {
    projectName: 'Project Name',
    env: 'Enviroment',
    envPlaceholder: 'Select enveroment',
    selectPlugins: 'Plugins',
    selectPluginsPlaceholder: 'Select plugins',
    create: 'Create',
    createFailed: 'Create Failed',
    fullscreen: 'Fullscreen',
    initTips:
      'Initializing. If initialization fails, please execute the `sh ./init.sh` command manually',
  },
  pluginDesc: {
    inject: 'Dependency injection and Inversion of control',
    router: 'Router',
    view: 'Page rendering',
    mva: 'MVA Framework',
    pipe: 'Pipe (Used to format parameters)',
    filter: 'Filter (Used to intercept requests)',
    validator: 'Verify request parameters',
    testing: 'Unit testing',
    static: 'Static resources',
    swagger: 'Swagger pages',
    jwt: 'JWT authentication middleware',
    env: 'Environment configuration',
    logger: 'Logger',
  },
  envDesc: {
    native: 'Native',
    tencent: 'Tencent Cloud Function',
    tencentEvent: 'Event',
    tencentWeb: 'Web Http',
    selectFunctionType: 'Select function type',
    azure: 'Azure Function App"',
    aws: 'AWS Lambda',
    aliyun: 'Aliyun Function',
    aliyunEvent: 'Handling event requests',
    aliyunHttp: 'HTTP Requests',
    huawei: 'Huawei Cloud Function Workflow',
    huaweiEvent: 'Event Function',
    huaweiWeb: 'Web Function',
    micro: 'Microservice',
    selectMicro: 'Select the communication mode of Microservices',
  },
};
