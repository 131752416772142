export default {
  pluginSelecter: {
    projectName: '项目名称',
    env: '运行环境',
    envPlaceholder: '请选择运行环境',
    selectPlugins: '选择插件',
    selectPluginsPlaceholder: '请选择插件',
    create: '立即创建',
    createFailed: '创建失败',
    fullscreen: '全屏',
    initTips: '正在初始化。如果初始化失败，请手动执行 `sh ./init.sh` 命令',
  },
  pluginDesc: {
    inject: '依赖注入',
    router: '路由',
    view: '视图渲染',
    mva: 'MVA 框架',
    pipe: '管道 (用于参数格式化) ',
    filter: '过滤器 (用于拦截请求) ',
    validator: '请求参数校验',
    testing: '单元测试',
    static: '静态资源',
    swagger: 'Swagger 文档',
    jwt: 'Jwt 身份验证中间件',
    env: '环境配置插件',
    logger: '日志插件',
  },
  envDesc: {
    native: '原生 NodeJS',
    tencent: '腾讯云函数',
    tencentEvent: '事件函数',
    tencentWeb: 'Web 函数',
    selectFunctionType: '选择云函数类型',
    azure: 'Azure 函数应用',
    aws: 'AWS Lambda',
    aliyun: '阿里云函数计算',
    aliyunEvent: '处理事件请求',
    aliyunHttp: '处理 HTTP 请求',
    huawei: '华为云函数工作流',
    huaweiEvent: '事件函数',
    huaweiWeb: 'Web 函数',
    micro: '微服务',
    selectMicro: '选择微服务通讯方式',
  },
};
